body {
  background-color: #e5e5e5;
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-family: "Rubik";
}

p {
  margin-bottom: 0;
}

a, a:hover {
  color: #5d5d5d;
}

.app-navbar {
  margin-bottom: 2rem;
}

.navlink {
  color: #000;
}

.navlink:hover {
  color: #5d5d5d;
}

.active-navlink {
  border-bottom: 1px solid #000;
}

.app-link-container {
  padding-top: .5rem;
}

.app-link {
  color: #000;
}

.app-link-disabled {
  color: #8c8c8c;
  pointer-events: none; 
  cursor: default; 
}