.page-row {
  margin-right: 0;
  margin-left: 0;
}

.liquidity-chart {
  width: 100%;
  margin-bottom: 2rem;
}

.margin-bottom {
  margin-bottom: 2rem;
}

.secondary-text {
  color: #5d5d5d;
  margin-top: 1rem;
  margin-bottom: 3.5rem;
}

code {
  color: #545454;
}